<template>
  <div class="add-activity-page">
    <div class="app-container">
      <el-form
        :model="dataForm"
        :rules="rules"
        ref="dataForm"
        label-width="140px"
        class="form-con-center3"
      >
        <el-form-item label="药品简称" required>
          <el-input style="width: 350px" placeholder="请输入药品简称" v-model="dataForm.drugsSimpleName"></el-input>
        </el-form-item>
        <el-form-item label="药品全称" required>
          <el-input style="width: 350px" placeholder="请输入药品全称" v-model="dataForm.drugsFullName"></el-input>
        </el-form-item>
        <el-form-item label="用法" required>
          <el-select v-model="dataForm.drugsUseType" placeholder="请选择" style="width: 350px">
            <el-option
              v-for="item in yfTypeArr"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="单位" required>
          <el-select v-model="dataForm.drugsUseUnit" placeholder="请选择活动类型" style="width: 350px">
            <el-option
              v-for="item in dwTypeArr"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="基础剂量" required>
          <el-input style="width: 350px" placeholder="请输入基础剂量" v-model="dataForm.drugsBaseDose"></el-input>
        </el-form-item>
        <el-form-item label="默认频率" required>
          <el-select v-model="dataForm.drugsDefaultRate" placeholder="请选择" style="width: 350px">
            <el-option label="是" :value="1"></el-option>
            <el-option label="否" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="频次" required>
          <el-select v-model="dataForm.drugsIntervalUnit" placeholder="请选择" style="width: 350px">
            <el-option
              v-for="item in plTypeArr"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-input
            type="number"
            :min="1"
            style="width: 80px"
            placeholder=""
            v-model="dataForm.drugsIntervalNum"
          ></el-input>&nbsp;次
        </el-form-item>
        <el-form-item label="时间">
          <el-tag
            style="margin-right:20px;margin-bottom:10px"
            v-for="tag in tags"
            :key="tag.name"
            @close="handleClose(tag)"
            closable
            :type="tag.type"
          >{{tag.name}}</el-tag>
          <br v-if="tags.length>0">
          <el-time-select
            style="width: 350px"
            @change="addTime"
            v-model="timeChoice"
            :picker-options="{
            start: '00:00',
            step: '00:15',
            end: '24:00'
          }"
            placeholder="选择时间"
          ></el-time-select>
        </el-form-item>
        <el-form-item label="常规用药" required>
          <el-select v-model="dataForm.drugsIsRoutine" placeholder="请选择" style="width: 350px">
            <el-option label="是" :value="1"></el-option>
            <el-option label="否" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="厂商">
          <el-input style="width: 350px" placeholder="请输入" v-model="dataForm.drugsProductFirm"></el-input>
        </el-form-item>
        <el-form-item label="药品简介" required>
          <el-input
            type="textarea"
            :rows="4"
            style="width: 350px"
            placeholder="请输入药品简介"
            v-model="dataForm.drugsIntroduce"
          ></el-input>
        </el-form-item>
        <el-form-item class="last-el-form-item">
          <!-- 1 -->
          <el-button type="primary" @click="submitForm()">保存</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import qs from "qs";
export default {
  name: "activityAdd",
  components: {},
  data() {
    return {
      id: "",
      cityInfo: "",
      dwTypeArr: [],
      yfTypeArr: [],
      plTypeArr: [],
      singlePrice: null, //单个人价格
      imageUrl: "",
      timeChoice: "",
      tags: [],
      typeArr: [], //活动类型
      dataForm: {
        drugsSimpleName: "",
        drugsFullName: "",
        drugsUseType: "",
        drugsUseUnit: "",
        drugsBaseDose: "",
        drugsDefaultRate: "",
        drugsIntervalNum: "",
        drugsIntervalUnit: "",
        drugsUseTime: "",
        drugsIsRoutine: "",
        drugsProductFirm: "",
        drugsIntroduce: "",
        hospitalId: "",
        deptId: "",
      },
      rules: {},
      flag: true,
    };
  },
  mounted() {
    this.getTypeArr();
  },
  methods: {
    handleClose(tag) {
      this.tags.splice(this.tags.indexOf(tag), 1);
    },
    addTime() {
      if (
        this.dataForm.drugsIntervalNum != "" &&
        this.tags.length < this.dataForm.drugsIntervalNum
      ) {
        var obj = { name: this.timeChoice, type: "" };
        this.tags.push(obj);
      }
    },

    getTypeArr() {
      this.$http({
        url: this.$http.adornUrl("/dict/list?type=用法"),
        method: "get",
      }).then(({ data }) => {
        this.yfTypeArr = data.data;
      });
      this.$http({
        url: this.$http.adornUrl("/dict/list?type=单位"),
        method: "get",
      }).then(({ data }) => {
        this.dwTypeArr = data.data;
      });
      this.$http({
        url: this.$http.adornUrl("/dict/list?type=频率"),
        method: "get",
      }).then(({ data }) => {
        this.plTypeArr = data.data;
        this.dataForm = JSON.parse(sessionStorage.medicineInfo);
        this.dataForm.drugsUseUnit = parseInt(this.dataForm.drugsUseUnit);
        var timeArr = this.dataForm.drugsUseTime.split(",");

        timeArr.forEach((item) => {
          var obj = { name: item, type: "" };
          this.tags.push(obj);
        });
      });
    },

    // 获取活动详情
    getActitityInfo(id) {
      this.$http({
        url: this.$http.adornUrl("/activityInfo/info"),
        params: { id: id },
        method: "get",
      }).then(({ data }) => {
        this.dataForm = data.data.activityInfo;
        this.singlePrice = this.dataForm.price;
        //判断活动形式
        if (this.dataForm.activityForm === 1) {
          this.dataForm.locationCounty = JSON.parse(
            this.dataForm.locationCounty
          );
          this.dataForm.locationProvince = JSON.parse(
            this.dataForm.locationProvince
          );
          this.dataForm.locationCity = JSON.parse(this.dataForm.locationCity);
          this.cityInfo = {
            locationCounty: this.dataForm.locationCounty,
            locationProvince: this.dataForm.locationProvince,
            locationCity: this.dataForm.locationCity,
          };
        }

        if (this.dataForm.groupbuyPrice) {
          let obj = JSON.parse(this.dataForm.groupbuyPrice);
          let result = Object.keys(obj).map((el) => {
            return { [el]: obj[el] };
          });
          let arr = [];
          for (let i = 0; i < result.length; i++) {
            for (let key in result[i]) {
              let obj = {
                num: key,
                price: result[i][key],
              };
              arr.push(obj);
            }
          }
          for (let i = 0; i < arr.length; i++) {
            this.$set(this.groupbuyPriceList, i, arr[i]);
          }
        } else {
          this.dataForm.groupbuyPrice = [];
        }
        if (this.dataForm.quotaNum > -1) {
          this.memberTotal = this.dataForm.quotaNum;
          this.dataForm.quotaNum = 1;
        }
        if (this.dataForm.isWx === 1) {
          this.wxShowRemainderNum = true;
        } else {
          this.wxShowRemainderNum = false;
        }

        this.imageUrl = data.data.activityInfo.coverUrl;
      });
    },
    upNumber(e) {
      // 输入框中只允许输入数字
      e.target.value = e.target.value.replace(/[^\d]/g, "");
      // 输入框中只允许输入小数点和数字，小数点后只能跟一位
      e.target.value = e.target.value.match(/^\d*(\.?\d{0,2})/g)[0] || null;
    },
    submitForm() {
      var that = this;
      this.dataForm.hospitalId = JSON.parse(localStorage.userInfo).deptPid;
      this.dataForm.deptId = JSON.parse(localStorage.userInfo).deptId;

      if (!this.dataForm.drugsSimpleName) {
        this.$message.error("请输入药品简称");
        return;
      }
      if (!this.dataForm.drugsFullName) {
        this.$message.error("请输入药品全称");
        return;
      }
      if (!this.dataForm.drugsUseType) {
        this.$message.error("请选择用法");
        return;
      }
      if (!this.dataForm.drugsUseUnit) {
        this.$message.error("请选择单位");
        return;
      }
      if (!this.dataForm.drugsBaseDose) {
        this.$message.error("请输入基础剂量");
        return;
      }
      if (!this.dataForm.drugsDefaultRate) {
        this.$message.error("请选择默认频率");
        return;
      }
      if (!this.dataForm.drugsIntervalNum) {
        this.$message.error("请填写频次");
        return;
      }
      if (!this.dataForm.drugsIntervalUnit) {
        this.$message.error("请填写频次");
        return;
      }
      if (!this.dataForm.drugsIsRoutine) {
        this.$message.error("请选择是否常规用药");
        return;
      }
      if (!this.dataForm.drugsIntroduce) {
        this.$message.error("请填写药品简介");
        return;
      }

      if (this.tags.length > 0) {
        let arr = [];
        for (let index in that.tags) {
          arr.push(that.tags[index].name);
        }
        that.dataForm.drugsUseTime = arr.join(",");
      }

      that
        .$http({
          url: that.$http.adornUrl("/drugsManage/update"),
          method: "post",
          data: qs.stringify(that.dataForm),
        })
        .then(({ data }) => {
          if (data.status) {
            that.$message({
              message: data.msg,
              type: "success",
              duration: 1500,
              onClose: () => {
                this.$router.push({
                  name: "medicine/medicineList",
                });
              },
            });
          } else {
            that.flag = true;
            that.$message.error(data.msg);
          }
        });
    },
    /**
     * 移除图片
     */
    handleRemove(file, fileList) {
      for (let i = 0; i < this.myFileList.length; i++) {
        if (file.url == this.myFileList[i].url) {
          this.myFileList.splice(i, 1);
        }
      }
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 3 个文件，本次选择了 ${
          files.length
        } 个文件，共选择了 ${files.length + fileList.length} 个文件`
      );
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确认移除图片？`);
    },
    handleSuccess(response, file, fileList) {
      this.imageUrl = response.result.filePath;
    },
    getDate() {
      const date = new Date(),
        year = date.getFullYear(),
        month =
          date.getMonth() > 9 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`,
        day = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`,
        hh = date.getHours() > 9 ? date.getHours() : `0${date.getHours()}`,
        mm =
          date.getMinutes() > 9 ? date.getMinutes() : `0${date.getMinutes()}`;
      return `${year}${month}${day}${hh}${mm}`;
    },

    initCKEditor() {
      var that = this;
      var myFile = [];
      var index = 0;
      class UploadAdapter {
        constructor(loader) {
          var self = this;
          self.loader = loader;
          loader.file.then(function(result) {
            myFile.push(result);
          });
        }

        upload() {
          let size = myFile[index].size;
          const obj = new FormData();
          obj.append("file", myFile[index]);
          index++;
          return new Promise((resolve, reject) => {
            self.fullscreenLoading = true;
            that
              .$http({
                url: that.$http.adornUrl("/aliyun/oss/upload"),
                method: "post",
                headers: {
                  "Content-Type": "multipart/form-data",
                },
                data: obj,
              })
              .then(({ data }) => {
                self.fullscreenLoading = false;
                resolve({
                  default: data.result.filePath,
                });
              });
          }).catch((error) => {
            console.log(error);
          });
        }

        abort() {}
      }
      //初始化编辑器
      CKEditor.create(document.querySelector("#editor"), {
        removePlugins: ["MediaEmbed"], //除去视频按钮
        language: "zh-cn", // 中文
        ckfinder: {
          uploaded: 1,
          url: "file/upload",
          // 后端处理上传逻辑返回json数据,包括uploaded(选项true/false)和url两个字段
        },
      })
        .then((editor) => {
          if (
            that.dataForm.eventNotice != null &&
            that.dataForm.eventNotice != undefined
          ) {
            editor.setData(that.dataForm.eventNotice);
          }

          const toolbarContainer = document.querySelector("#toolbar-container");
          toolbarContainer.appendChild(editor.ui.view.toolbar.element);
          // 加载了适配器
          editor.plugins.get("FileRepository").createUploadAdapter = (
            loader
          ) => {
            return new UploadAdapter(loader);
          };
          this.editor = editor; // 将编辑器保存起来，用来随时获取编辑器中的内容等，执行一些操作
        })
        .catch((error) => {
          console.error(error);
        });
    },

    // 随机生成文件名
    random_string(len) {
      len = len || 32;
      let chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz12345678",
        maxPos = chars.length,
        pwd = "";
      for (let i = 0; i < len; i++) {
        pwd += chars.charAt(Math.floor(Math.random() * maxPos));
      }
      return pwd;
    },
    /**
     * 图片限制
     */
    beforeAvatarUpload(file) {
      let fileName = file.name;
      let pos = fileName.lastIndexOf(".");
      let lastName = fileName.substring(pos, fileName.length);
      let type = lastName.toLowerCase();
      const isLt20M = file.size / 1024 / 1024 < 20;
      if (type != ".jpg" && type != ".jpeg" && type != ".png") {
        this.$message.error("图片格式只支持jpg、jpeg、png格式");
      }
      if (!isLt20M) {
        this.$message.error("上传文件大小不能超过 20MB!");
      }
      return type && isLt20M;
    },

    handleChange(file, fileList) {
      this.fileList = fileList.slice(-3);
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
  /* watch: {
    "dataForm.activityForm"(value) {
      if (value == 1 && this.id) {
        this.getActitityInfo(this.id);
      }
    },
  }, */
};
</script>

<style>
.group-list {
  float: left;
  margin-right: 20px;
  margin-bottom: 20px;
}
</style>